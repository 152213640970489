import { useHistory } from 'react-router';
import { Button } from '@components/button';

import styles from './styles/Welcome.module.scss';
import AuraLogo from '../../components/brand/AuraAppLogo.svg';

function Welcome() {
  const history = useHistory();
  const email = new URLSearchParams(history.location.search).get('email');
  const [, domain] = (email || '').split('@');

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          {domain && (
            // <img
            //   src={`https://dev.api.getaura.ai/v2/orgusers/user_organization_logo/?domain=${domain}`}
            //   alt="logo"
            // />
            <img src={AuraLogo} alt="Aura Logo" style={{ width: '100%' }} />
          )}
        </div>
        <div className={styles.welcomeImage}>
          <img
            src="https://dev.api.getaura.ai/static/admin/img/welcome-messages-for-employees.png"
            alt="welcome"
          />
        </div>
        <div className={styles.footer}>
          <Button
            title="Get started"
            onClick={() => {
              const state = new URLSearchParams(history.location.search).get(
                'state'
              );
              if (state) {
                window.location = `https://auth.aura.ceo/continue?state=${state}`;
              }
            }}
          />
          <small>©2023 Aura. All rights reserved.</small>
        </div>
      </div>
    </div>
  );
}

export default Welcome;

import { useState, useEffect } from 'react';

/**
 * Custom hook to track and listen for changes to window.location.pathname
 * @returns {string} The current pathname
 */
const usePathname = () => {
  const [pathname, setPathname] = useState(window.location.pathname);

  useEffect(() => {
    console.log('Setting up event listeners');

    // Function to update pathname state
    const handlePathnameChange = () => {
      setPathname(window.location.pathname);
    };

    // Listen for popstate event (triggered on browser back/forward)
    window.addEventListener('popstate', handlePathnameChange);

    // Listen for pushstate and replacestate events
    // These are custom events we need to dispatch when using history.pushState/replaceState
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function(...args) {
      originalPushState.apply(this, args);
      window.dispatchEvent(new Event('pushstate'));
      window.dispatchEvent(new Event('locationchange'));
    };

    window.history.replaceState = function(...args) {
      originalReplaceState.apply(this, args);
      window.dispatchEvent(new Event('replacestate'));
      window.dispatchEvent(new Event('locationchange'));
    };

    window.addEventListener('pushstate', handlePathnameChange);
    window.addEventListener('replacestate', handlePathnameChange);
    window.addEventListener('locationchange', handlePathnameChange);

    // Clean up event listeners on unmount
    return () => {
      console.log('Removing event listeners');
      window.removeEventListener('popstate', handlePathnameChange);
      window.removeEventListener('pushstate', handlePathnameChange);
      window.removeEventListener('replacestate', handlePathnameChange);
      window.removeEventListener('locationchange', handlePathnameChange);

      // Restore original history methods
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  return pathname;
};

export default usePathname;

import { BGTaskType } from '@common/constants';
import { useAuthContext } from '@state/auth';
import { useBackgroundTasksContext } from '@state/backgroundTasks';
import { DiligenceContextProvider } from '@state/diligence';
import { JobPostingsContextProvider } from '@state/jobPostings';
import { TalentPoolContextProvider } from '@state/talentPool';
import { useBeforeUnload } from '@util/hooks';
import { lazyWithPrefetch } from '@util/prefetch';
import { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import WalkThrough from '../WalkThrough';
import AccessDenied from './AccessDenied';
import Welcome from './Welcome';
import AppLoading from './AppLoading';

import styles from './styles/App.module.scss';

// children
import FallbackSpinner from '@components/loader/FallbackSpinner';
import BackgroundTasksUI from './BackgroundTasksUI';
import Header from './Header';
// navigation
import ModuleSelector from '../moduleSelector/ModuleSelector';

import Modals from '@views/modals';
import { checkIsLocked } from '@views/moduleSelector/ModuleSelector';
import { Login, Logout } from './Auth';
import TrialExpired from './TrialExpired';
import { useLoading } from '@state/loading';

// admin dashboard
const Admin = lazy(() => import('../admin'));
// diligence
const Dashboards = lazyWithPrefetch(() => import('../dashboards/Dashboards'));
const TalentInsights = lazyWithPrefetch(() =>
  import('../dashboards/TalentInsights')
);
const Dashboard = lazyWithPrefetch(() => import('../dashboard/Dashboard'));
const TalentInsightsDashboard = lazyWithPrefetch(() =>
  import('../dashboard/TalentInsightsDashboard')
);
const Roles = lazyWithPrefetch(() => import('../roles/RolesRedirector'));
const NamingTable = lazyWithPrefetch(() => import('../roles/NamingTable'));
const Companies = lazyWithPrefetch(() => import('../companies/Companies'));
const Project = lazyWithPrefetch(() => import('../project/Project'));
const Projects = lazyWithPrefetch(() => import('../projects/Projects'));

const ProtectedComponent = lazyWithPrefetch(() =>
  import('../../components/ProtectedComponent')
);
// company search
const CompanySearch = lazyWithPrefetch(() =>
  import('../companySearch/CompanySearch')
);
// talent finder
const LeadFinder = lazyWithPrefetch(() => import('../leadFinder/LeadFinder'));
// Job Postings
const JobPostings = lazyWithPrefetch(() => import('../jobPostings'));
// Salary Benchmark
const SalaryBenchmark = lazyWithPrefetch(() => import('../salaryBenchmark'));
// Talent Pool
const TalentPool = lazyWithPrefetch(() => import('../talentPool'));
// Databox
const Databox = lazyWithPrefetch(() => import('../databox'));
// Corporate
const Corporate = lazyWithPrefetch(() => import('../corporate'));

export default function App() {
  const { isAuthenticated, userRole, userSubscriptions } = useAuthContext();
  const {
    platformFeatureStandaloneJobPostingsModule,
    platformFeatureZendeskChat,
  } = useFlags();

  const { loading, ready } = useLoading();

  // prompt user to confirm before leaving the page if there are any incomplete downloads
  const setDirty = useBeforeUnload();
  const { tasks } = useBackgroundTasksContext();
  const incompleteDownloadTasks = tasks?.filter(
    task => !task.done && [BGTaskType.EXPORT].includes(task.type)
  );

  useEffect(() => {
    if (window.location.href.includes('diligence')) {
      const newUrl = window.location.href.replace('diligence', 'insights-hub');
      window.location.href = newUrl;
    }
  }, []);

  useEffect(() => {
    const scriptId = 'ze-snippet';
    if (platformFeatureZendeskChat) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        script.src =
          'https://static.zdassets.com/ekr/snippet.js?key=362d9d41-9bcb-4e49-939c-ed9cef35fbe5';
        script.async = true;
        script.onload = () => {
          console.log('Zendesk chat script loaded');
        };
        document.body.appendChild(script);
      }
    } else {
      const script = document.getElementById(scriptId);
      script?.remove() && console.log('Zendesk chat script removed');
    }

    return () => {
      const script = document.getElementById(scriptId);
      script?.remove();
    };
  }, [platformFeatureZendeskChat]);

  useEffect(() => {
    setDirty(incompleteDownloadTasks?.length > 0);
  }, [incompleteDownloadTasks?.length, setDirty]);

  useEffect(() => {
    Dashboards.prefetch();
    TalentInsights.prefetch();
    Dashboard.prefetch();
    Roles.prefetch();
    NamingTable.prefetch();
    Companies.prefetch();
    Project.prefetch();
    Projects.prefetch();
    CompanySearch.prefetch();
    LeadFinder.prefetch();
    ProtectedComponent.prefetch();
  }, []);

  if (loading) {
    return <AppLoading />;
  }

  return (
    <BrowserRouter
      getUserConfirmation={() => {
        // Empty callback is necessary to trick the browser to block the default browser prompt
      }}
    >
      <Switch>
        <Route path="/login" render={() => <Login />} />
        <Route path="/logout" render={() => <Logout />} />
        <Route path="/welcome" render={() => <Welcome />} />
        {!isAuthenticated && (
          <Route
            path="/"
            render={props => {
              const url = new URLSearchParams(props.location.search);
              const error = url.get('error');
              return ['access_denied', 'unauthorized'].includes(error) ? (
                <AccessDenied url={url} />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
        )}
        {!ready ? (
          <Route path="/" render={() => <AppLoading />} />
        ) : (
          <Route
            path="/"
            render={() => (
              <>
                <WalkThrough />
                <Modals />
                <BackgroundTasksUI />
                <div className={styles.wrapper}>
                  <Header />
                  <Switch>
                    <Route exact path="/" component={ModuleSelector} />
                    <Suspense fallback={<FallbackSpinner />}>
                      <DiligenceContextProvider>
                        <Route
                          exact
                          path="/trial-expired"
                          component={TrialExpired}
                        />
                        <Route exact path="/insights-hub">
                          {checkIsLocked('insights-hub', userSubscriptions) ? (
                            <Redirect to="/" />
                          ) : (
                            <Projects />
                          )}
                        </Route>
                        <Switch>
                          <Route
                            exact
                            path="/insights-hub/templates"
                            component={Projects}
                          />
                          <Route
                            exact
                            path="/insights-hub/:projectId"
                            render={props => (
                              <ProtectedComponent
                                Component={Project}
                                {...props}
                              />
                            )}
                          />
                        </Switch>
                        <Route
                          exact
                          path="/insights-hub/:projectId/companies"
                          render={props => (
                            <ProtectedComponent
                              Component={Companies}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/dashboards"
                          render={props => (
                            <ProtectedComponent
                              Component={Dashboards}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/dashboard/:dashboardTitle"
                          render={props => (
                            <ProtectedComponent
                              Component={Dashboard}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/talentInsights"
                          render={props => (
                            <ProtectedComponent
                              Component={TalentInsights}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/talentInsights/:dashboardTitle"
                          render={props => (
                            <ProtectedComponent
                              Component={TalentInsightsDashboard}
                              {...props}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/roles"
                          render={props => (
                            <ProtectedComponent Component={Roles} {...props} />
                          )}
                        />
                        <Route
                          exact
                          path="/insights-hub/:projectId/role_naming"
                          render={props => (
                            <ProtectedComponent
                              Component={NamingTable}
                              {...props}
                            />
                          )}
                        />
                      </DiligenceContextProvider>
                      <Route exact path="/company-search">
                        {checkIsLocked('sourcing', userSubscriptions) ? (
                          <Redirect to="/" />
                        ) : (
                          <CompanySearch />
                        )}
                      </Route>
                      <Route
                        exact
                        path="*/company-search/*"
                        component={CompanySearch}
                      />
                      <Route exact path="/lead-finder">
                        {checkIsLocked('lead-finder', userSubscriptions) ? (
                          <Redirect to="/" />
                        ) : (
                          <LeadFinder />
                        )}
                      </Route>
                      {platformFeatureStandaloneJobPostingsModule && (
                        <JobPostingsContextProvider>
                          <Route exact path="/job-postings">
                            {checkIsLocked(
                              'job-postings',
                              userSubscriptions
                            ) ? (
                              <Redirect to="/" />
                            ) : (
                              <JobPostings />
                            )}
                          </Route>
                        </JobPostingsContextProvider>
                      )}
                      <Route exact path="/databox">
                        {checkIsLocked('databox', userSubscriptions) ? (
                          <Redirect to="/" />
                        ) : (
                          <Databox />
                        )}
                      </Route>
                      <Route exact path="/salary-benchmark">
                        {checkIsLocked(
                          'salary-benchmark',
                          userSubscriptions
                        ) ? (
                          <Redirect to="/" />
                        ) : (
                          <SalaryBenchmark />
                        )}
                      </Route>
                      <TalentPoolContextProvider>
                        <Route exact path="/talent-pool">
                          {checkIsLocked('talent-pool', userSubscriptions) ? (
                            <Redirect to="/" />
                          ) : (
                            <TalentPool />
                          )}
                        </Route>
                      </TalentPoolContextProvider>
                      <Route exact path="/admin">
                        {userRole === 'Admin' ? <Admin /> : <Redirect to="/" />}
                      </Route>
                      <Route exact path="/corporate">
                        <Corporate />
                      </Route>
                      <TalentPoolContextProvider>
                        <Route
                          exact
                          path="/corporate/find-talent"
                          component={TalentPool}
                        />
                      </TalentPoolContextProvider>
                      <Route exact path="/corporate/fill-talent-gaps">
                        <LeadFinder directSelectCase={'talent'} />
                      </Route>
                    </Suspense>
                    <Redirect to="/" />
                  </Switch>
                </div>
              </>
            )}
          />
        )}
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
}

import { useEffect, useCallback } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useLoading } from './loading';
import { useAuthContext } from './auth';
import { useLDProvider } from './launchDarkly';
const REDIRECT_PATH_30 = '/explore';

const RedirectionCheckProvider = ({ children }) => {
  const { platformFeatureLegacyRedirect } = useFlags();
  const { user } = useAuthContext();
  const { setLoading, setReady } = useLoading();
  const { initialized } = useLDProvider();

  const stayOrRedirect = useCallback(() => {
    if (initialized) {
      if (!!user.email_verified && platformFeatureLegacyRedirect === true) {
        setReady(true);
        setLoading(false);
        return;
      }
      // Now we can be confident the flag value is correct
      if (!!user.email_verified && platformFeatureLegacyRedirect === false) {
        // Redirect to the new platform
        setLoading(false);
        setTimeout(function() {
          setReady(true);
        }, 7000);
        window.location.href = REDIRECT_PATH_30;

        return; // Don't set loading to false if we're redirecting
      }
    }

    setLoading(false);
    // Only set loading to false if we're not redirecting
  }, [platformFeatureLegacyRedirect, setLoading, user, initialized, setReady]);

  useEffect(() => {
    stayOrRedirect();
  }, [stayOrRedirect]);

  return <>{children}</>;
};

export default RedirectionCheckProvider;

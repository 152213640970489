import PropTypes from 'prop-types';
import { Router } from 'react-router';
import { createBrowserHistory } from 'history';
import { StateContextProvider } from '@state';
import { BackgroundTasksContextProvider } from '@state/backgroundTasks';
import { AuthContextProvider } from '@state/auth';
import LDProvider from '@state/launchDarkly';
import RedirectionCheckProvider from '@state/redirectionCheck';
import ErrorBoundary from '@views/app/ErrorBoundary';
import { LoadingProvider } from '@state/loading';
function Providers({ children }) {
  const history = createBrowserHistory();
  return (
    <ErrorBoundary>
      <LoadingProvider>
        <Router history={history}>
          <AuthContextProvider>
            <LDProvider>
              <RedirectionCheckProvider>
                <StateContextProvider>
                  <BackgroundTasksContextProvider>
                    {children}
                  </BackgroundTasksContextProvider>
                </StateContextProvider>
              </RedirectionCheckProvider>
            </LDProvider>
          </AuthContextProvider>
        </Router>
      </LoadingProvider>
    </ErrorBoundary>
  );
}

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
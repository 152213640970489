import PropTypes from 'prop-types';
import buttonSettings from './buttonSettings';
import Icon from '../icon';
import Colors from '../../styles/colors';
import { Spinner } from '../../components/loader';
import classnames from 'classnames';

import styles from './button.module.scss';

export const LoadingStates = {
  LOADING: 'loading',
  LOADING_FINISHED: 'loadingFinished',
  NOT_LOADING: 'notLoading',
};

export function Button({
  title = '',
  type = 'primary',
  disabled = false,
  icon = '',
  iconSize = 'xsmall',
  viewBox = '0 0 32 32',
  compact = false,
  iconFill,
  iconStyle,
  loadingState = LoadingStates.NOT_LOADING,
  onClick,
  iconPlacement = 'left',
  ...rest
}) {
  const getContent = loadingState => {
    switch (loadingState) {
      case LoadingStates.LOADING:
        return (
          <div className={styles.loaderContainer}>
            <Spinner
              icon={
                type === 'primary' ? 'tailSpinLoader' : 'coloredTailSpinLoader'
              }
              width="16"
              height="16"
            />
          </div>
        );
      case LoadingStates.LOADING_FINISHED:
        return (
          <div className={styles.loaderContainer}>
            <Icon
              icon={'check'}
              viewBox={'0 0 24 24'}
              size={'medium'}
              fill={getIconFill()}
              style={{ margin: 'auto', height: '16' }}
            />
          </div>
        );
      default: {
        if (!icon && !title) return null;
        const iconElement = icon && (
          <Icon
            icon={icon}
            viewBox={viewBox}
            size={iconSize}
            fill={getIconFill()}
            className={classnames({
              [styles.icon]: title !== ''
            })}
            style={iconStyle}
          />
        );
        const content = [
          iconPlacement === 'left' ? <span key="left-icon">{iconElement}</span> : null,
          <span key="title">{title}</span>,
          iconPlacement === 'right' ? <span key="right-icon">{iconElement}</span> : null
        ].filter(Boolean);

        return <>{content}</>;
      }
    }
  };

  const getIconFill = () => {
    if (disabled) {
      return Colors.gray300;
    }

    if (iconFill !== undefined) {
      return iconFill;
    }

    switch (type) {
      case 'primary':
        return Colors.white;
      case 'secondary':
        return Colors.violet;
      case 'ghost':
        return Colors.gray300;
      case 'borderless':
        return Colors.gray500;
      default:
        return Colors.gray500;
    }
  };

  const getSecondaryCSSClass = () => {
    const hasTitle = title !== '';

    if (hasTitle && !compact) {
      return styles.hasTitle;
    } else if (hasTitle && compact) {
      return styles.hasTitleCompact;
    } else if (!hasTitle && !compact) {
      return styles.iconOnly;
    } else if (!hasTitle && compact) {
      return styles.iconOnlyCompact;
    }
  };

  return (
    <button
      className={classnames(
        styles.font,
        styles.default,
        getSecondaryCSSClass(),
        styles[type]
      )}
      disabled={disabled}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        if (loadingState !== LoadingStates.LOADING && !disabled) {
          onClick(event);
        }
      }}
      {...rest}
    >
      {getContent(loadingState)}
    </button>
  );
}

Button.propTypes = {
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconFill: PropTypes.string,
  iconSize: PropTypes.string,
  iconStyle: PropTypes.object,
  loadingState: PropTypes.oneOf(Object.values(LoadingStates)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  viewBox: PropTypes.string,
};

export function TextButton({ title = 'Text Button', onClick = () => { } }) {
  return (
    <div className={styles.textButton} onClick={onClick}>
      {title}
    </div>
  );
}

TextButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export function IconButton({
  icon,
  title,
  type,
  disabled,
  loadingState = LoadingStates.NOT_LOADING,
  iconAtRight = false,
  iconFill,
  ...rest
}) {
  var buttonType = {
    iconFill: iconFill || 'grey',
    iconButtonStyle: {
      withText: null,
      withoutText: null,
    },
  };
  buttonType = type ? buttonSettings[type] || buttonType : buttonType;

  var buttonClassName = 'primaryIconButtonWithoutText';
  if (title == null) {
    buttonClassName = buttonType['iconButtonStyle']['withoutText'];
  } else {
    buttonClassName = buttonType['iconButtonStyle']['withText'];
  }

  const getContent = loadingState => {
    switch (loadingState) {
      case LoadingStates.LOADING:
        return (
          <>
            <div className={styles.iconLoaderContainer}>
              <Spinner
                icon={
                  type === 'primary'
                    ? 'tailSpinLoader'
                    : 'coloredTailSpinLoader'
                }
                width="16"
                height="16"
              />
            </div>
            {title == null ? null : (
              <div className={styles.iconButtonTitle}>{title}</div>
            )}
          </>
        );
      case LoadingStates.LOADING_FINISHED:
        return (
          <>
            <div className={styles.iconLoaderContainer}>
              <Icon
                icon={'check'}
                viewBox={'0 0 24 24'}
                size={'medium'}
                style={{ margin: 'auto', width: '16', height: '16' }}
                fill={buttonType.iconFill}
              />
            </div>
            {title == null ? null : (
              <div className={styles.iconButtonTitle}>{title}</div>
            )}
          </>
        );
      default: {
        const ButtonIcon = () => (
          <Icon
            icon={icon || 'settings'}
            size="xsmall"
            fill={disabled ? 'grey' : buttonType.iconFill}
            viewBox={rest.viewBox}
          />
        );
        return (
          <>
            {!iconAtRight && <ButtonIcon />}
            {title == null ? null : (
              <div
                className={iconAtRight ? styles.iconAtRight : styles.iconAtLeft}
              >
                {title}
              </div>
            )}
            {iconAtRight && <ButtonIcon />}
          </>
        );
      }
    }
  };

  return (
    <button
      className={
        typeof buttonType === 'object' ? styles[buttonClassName] : 'button'
      }
      disabled={disabled}
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      {...rest}
    >
      {getContent(loadingState)}
    </button>
  );
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  loadingState: PropTypes.oneOf(Object.values(LoadingStates)),
  title: PropTypes.string,
  type: PropTypes.string,
};

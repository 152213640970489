import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, LoadingStates } from '../button';
import classnames from 'classnames';

import styles from './modal.module.scss';

function Modal({
  children,
  contentComponent,
  className = '',
  contentClassName = '',
  isVisible,
  isClosable = true,
  footerButtonPropsList,
  headerText,
  hasCloseButton = false,
  closeButtonPosition = 'left',
  contentScrollable = true,
  onClose = () => {},
  footerClassName = '',
  containerStyle,
  footerContent,
  contentStyle = {}, 
}) {
  const [footerProps, setFooterProps] = useState([]);
  const [preventClicking, setPreventClicking] = useState(false);

  useEffect(() => {
    const btnClickTimeout = setTimeout(() => {
      setPreventClicking(false);
    }, 1000);

    return () => clearTimeout(btnClickTimeout);
  }, [preventClicking]);

  useEffect(() => {
    if (footerButtonPropsList.length) {
      setFooterProps(footerButtonPropsList.reverse());
    }
  }, [footerButtonPropsList]);

  if (!isVisible) {
    return null;
  } else {
    return (
      <div
        id="modalOverlay"
        className={styles.overlay}
        onClick={e => {
          if (e.target && e.target.id === 'modalOverlay' && isClosable) {
            onClose();
          }
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div
          className={classnames(styles.container, className)}
          style={{ ...containerStyle }}
        >
          <div className={classnames(styles.font, styles.header)}>
            {headerText}
            {hasCloseButton && closeButtonPosition ? (
              <div
                className={classnames(
                  styles.closeButtonContainer,
                  styles[closeButtonPosition]
                )}
              >
                <IconButton
                  icon="crossMark"
                  viewBox="0 0 16 16"
                  type="iconOnly"
                  onClick={onClose}
                />
              </div>
            ) : null}
          </div>
          <div
            className={classnames(
              {
                [styles.contentScrollable]: contentScrollable,
                [styles.contentNotScrollable]: !contentScrollable,
              },
              contentClassName
            )}
            style={contentStyle}
          >
            {children || contentComponent}
          </div>
          <div className={classnames(styles.footer, footerClassName)}>
            {footerContent}
            {/* Reverse list so buttons are added right -> left, so most important button is first */}
            {footerProps.map(footerButtonProps => (
              <Button
                key={footerButtonProps.title}
                title={footerButtonProps.title}
                type={footerButtonProps.type || 'primary'}
                onClick={() => {
                  if (
                    !preventClicking &&
                    footerButtonProps?.loadingState !== LoadingStates.LOADING
                  ) {
                    footerButtonProps.onClick();
                    setPreventClicking(true);
                  }
                }}
                loadingState={footerButtonProps.loadingState}
                disabled={footerButtonProps.disabled}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  contentComponent: PropTypes.object,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  isVisible: PropTypes.bool,
  isClosable: PropTypes.bool,
  footerButtonPropsList: PropTypes.array,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasCloseButton: PropTypes.bool,
  closeButtonPosition: PropTypes.oneOf(['left', 'right']),
  contentScrollable: PropTypes.bool,
  onClose: PropTypes.func,
  footerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  footerContent: PropTypes.node,
  contentStyle: PropTypes.object,
};

export { Modal };

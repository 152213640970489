import React, {
  useEffect,
  useState,
  useCallback,
  createContext,
  useContext,
} from 'react';
import { env } from '@common/env';
import { useAuthContext } from '@state/auth';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { hashData } from '@util/hash';
import Cookies from 'js-cookie';
import usePathname from '../hooks/usePathname';
import AppLoading from '@views/app/AppLoading';

const clientSideID = String(env('REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID'));

// Create a context to expose LD initialization state
const LDInitContext = createContext({ initialized: false });

// Hook to access LD initialization state
export const useLDInitialized = () => useContext(LDInitContext);

const LDProvider = ({ children }) => {
  const { user } = useAuthContext();
  const [provider, setProvider] = useState(null);
  const [initialized, setInitialized] = useState(false);

  // Use the custom hook to listen for pathname changes
  const currentPathname = usePathname();

  const getUserId = useCallback(async () => {
    const hashedId = await hashData(String(user.id));
    return hashedId;
  }, [user]);

  const getLDContext = useCallback(
    hashedId => {
      return {
        kind: 'user',
        key: user.id,
        email: user.email,
        path: `legacy:${currentPathname}`,
        selectedPlatformVersion: Cookies.get(
          `selectedPlatformVersion_${hashedId}`
        ),
      };
    },
    [user, currentPathname]
  );

  useEffect(() => {
    if (user) {
      const initLDProvider = async () => {
        const hashedId = await getUserId();
        const ldProvider = await asyncWithLDProvider({
          clientSideID,
          context: getLDContext(hashedId),
        });

        setProvider(() => ldProvider);

        // Set initialized to true after provider is set
        // This ensures flags are available
        setInitialized(true);
      };

      initLDProvider();
    }
  }, [user, currentPathname, getUserId, getLDContext]);

  if (
    (!initialized || !provider) &&
    currentPathname !== '/login' &&
    currentPathname !== '/logout'
  ) {
    return <AppLoading />;
  }

  if (currentPathname === '/login' || currentPathname === '/logout') {
    return <>{children}</>;
  }

  const ProviderComponent = provider;
  return (
    <ProviderComponent>
      <LDInitContext.Provider value={{ initialized }}>
        {children}
      </LDInitContext.Provider>
    </ProviderComponent>
  );
};

const useLDProvider = () => {
  const context = useContext(LDInitContext);
  return context;
};

export default LDProvider;
export { useLDProvider };
